<template>
  <div class="data-collection-toolbar">
    <div class="box">
      <div class="left leftFlex">
        <!--地址栏-->
        <div class="data-collection-toolbar-addressbar">
          <div class="url_box">
            <div class="menu">
              <el-tooltip
                effect="light"
                content="一键采集当前页面文本，转换成为docx文档存入智能网盘，该文档解析后可用于智能推文"
                placement="bottom"
              >
                <span class="menu-item collect" @click.stop="dataAcquisition">
                  <img
                    :src="require('assets/images/dataCollection/ie.png')"
                    alt=""
                  />

                  <span>一键采集</span>
                </span>
              </el-tooltip>
              <!--  一键采集的docx文档存在智能网盘/网页采集文件夹 -->
              <el-tooltip
                effect="light"
                content="一键采集的docx文档存在智能网盘/网页采集文件夹"
                placement="bottom"
              >
                <span class="menu-item docs" @click="locationDiskCollectFolder">
                  <img
                    :class="twinkling ? 'className' : ''"
                    :src="
                      require('assets/images/dataCollection/collect-doc.png')
                    "
                    alt=""
                  />
                  <span :style="{ marginTop: twinkling ? '24px' : '' }"
                    >存储</span
                  >
                </span>
              </el-tooltip>
              <span class="menu-item home" @click="homePage">
                <img
                  :src="require('assets/images/dataCollection/homepage.png')"
                  alt=""
                />
              </span>
              <span class="menu-item back option" @click="backLink">
                <img
                  :src="
                    listIndex == 0
                      ? require('assets/images/dataCollection/left.png')
                      : require('assets/images/dataCollection/left-active.png')
                  "
                  alt=""
                />
              </span>
              <span class="menu-item forward option" @click="forwardLink">
                <img
                  :src="
                    listIndex == histroyList.length - 1
                      ? require('assets/images/dataCollection/right.png')
                      : require('assets/images/dataCollection/right-active.png')
                  "
                  alt=""
                />
              </span>
              <span class="menu-item refresh" @click="refresh">
                <img
                  :src="require('assets/images/dataCollection/refresh.png')"
                  alt=""
                />
                <!-- <span>刷新</span> -->
              </span>
              <!-- <span class="menu-item collection" @click="collectUrl">
            <img :src="require('assets/images/dataCollection/collect.png')" alt="" />
            <span>收藏</span>
          </span> -->
            </div>
            <div class="input_url_box">
              <!-- <el-dropdown @command="handleCommand" trigger="click">
              <span class="el-dropdown-link">
                {{ httpTop }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="http://">http://</el-dropdown-item>
                <el-dropdown-item command="https://">https://</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
              <div class="input_box">
                <el-input
                  ref="url"
                  placeholder="请粘贴或输入网址"
                  v-model="urlInput"
                  clearable
                  @keydown.native="handleKeydownUrl"
                  @focus="$refs['url'].select()"
                />
                <img
                  :src="require('assets/images/dataCollection/location.png')"
                  alt=""
                  @click="locationSend"
                />
              </div>
            </div>
          </div>
        </div>
        <!--网页采集进度条-->
        <DataCollectionProgress
          v-if="showCollectProgress"
          :percentage="collectPercent"
        />
        <DataCollectionMain v-if="showDataCollectionMain" />
        <div
          class="DataCollectionMain_content"
          :style="styleObject"
          v-if="!showDataCollectionMain && showIfare"
        >
          <iframe
            id="iframe"
            name="frame"
            :src="contentSrc"
            frameborder="0"
            ref="refed"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
import ajax from "utils/xhr";
import { gather, del } from "api/dataCollection";
import disablemenu from "./contextmenumixin";
import DataCollectionProgress from "components/dataCollection/DataCollectionProgress";
import DataCollectionMain from "./DataCollectionMain.vue";
import commonFooter from "components/footer/commonFooter.vue";
import recommend from "components/recommend/recommend.vue";
import { isHasFreeCount } from "api/common";
export default {
  components: {
    DataCollectionProgress,
    DataCollectionMain,
    recommend,
    commonFooter,
  },
  name: "DataCollectionToolBar",
  mixins: [disablemenu],
  data() {
    return {
      // ifarm显示隐藏
      showIfare: true,
      // 搜索地址
      contentSrc: "",
      // 搜索页面显示与隐藏
      showDataCollectionMain: true,

      histroyList: ["index"],
      listIndex: 0,
      styleObject: {
        width: "92%",
        height: "92%",
      },
      //标签页集合
      tabs: [
        {
          title: "空白页",
          icon: require("assets/images/dataCollection/broswer.png"),
          m_url: "",
          url:
            (process.env.NODE_ENV == "production"
              ? `${process.env.VUE_APP_BASE_API}`
              : "http://creword.com") + "/dataCollectionMain",
          winKey: "0",
        },
      ],
      //选中的标签索引
      tabIndex: 0,
      //协议类型
      httpTop: "http://",
      //默认地址
      defaultUrl:
        (process.env.NODE_ENV == "production"
          ? `${process.env.VUE_APP_BASE_API}`
          : "http://creword.com") + "/dataCollectionMain",
      //favicon.ico
      favicon: require("assets/images/dataCollection/broswer.png"),
      //地址栏输入
      urlInput: "",
      urlInputs: "",
      //收藏列表
      collectionList: [],
      //书签下拉列表
      bookDropList: [],
      //动画状态
      animated: false,
      //前进状态
      forward: false,
      //后退状态
      back: false,
      //显示采集进度条
      showCollectProgress: false,
      //采集进度
      collectPercent: 0,
      twinkling: false,
    };
  },
  mounted() {
    window.dataCollectionUrl = (content) => {
      //  alert(decodeURIComponent(content));
      let url = decodeURIComponent(content);
      if (url.indexOf("office.creword.com") != -1) {
        return;
      }
      this.urlInput = url;
      if (this.urlInput != this.urlInputs) {
        this.locationSend();
      }
      // console.log(this.$children);
    };

    window.onkeydown = (e) => {
      if (e.keyCode == "17") {
        window.onwheel = (event) => {
          // console.log(event);
          let w =
            this.styleObject.width.substring(
              0,
              this.styleObject.width.indexOf("%")
            ) * 1;
          let h =
            this.styleObject.height.substring(
              0,
              this.styleObject.height.indexOf("%")
            ) * 1;
          if (event.deltaY > 0) {
            if (w > 100 || h > 100) {
              return;
            }
            this.styleObject.width = w + 8 + "%";
            this.styleObject.height = h + 8 + "%";
          } else {
            if (w == 60 || h == 60) {
              return;
            }
            this.styleObject.width = w - 8 + "%";
            this.styleObject.height = h - 8 + "%";
          }
        };
      }
      console.log(e);
    };

    window.onkeyup = (e) => {
      window.onwheel = null;
    };
  },
  beforeDestroy() {
    window.onkeydown = null;
    if (this.urlInput) {
      return this.$store.commit("GeturlInput", this.urlInput);
    }
    return this.$store.commit("GeturlInput", "");
  },
  methods: {
    //生成窗口唯一的key
    getWinKey() {
      return new Date().getTime() + Math.random().toString(36).substr(2);
    },
    //添加空标签页
    addTabPage(search, title, icon) {
      const winKey = this.getWinKey();
      const url = search ? search : this.defaultUrl;
      this.tabs.push({
        title: title ? title : "空白页",
        icon: icon ? icon : this.favicon,
        m_url: "",
        url,
        winKey,
      });
      this.urlInput = search ?? "";
      this.tabIndex = this.tabs.length - 1;
      this.forward = false;
      this.back = false;
      this.$nextTick(() => {
        this.scrollTo();
      });
      const params = {
        url,
        winKey,
      };
      if (window.webkit) {
        window.webkit.messageHandlers.macAddTab.postMessage(
          JSON.stringify(params)
        );
      }
      ajax({
        url:
          (process.env.NODE_ENV == "production"
            ? `${process.env.VUE_APP_BASE_API}`
            : "http://creword.com") + "/notifiedClient/addTab",
        params,
      });
    },
    //关闭标签页
    closeTabPage(inx) {
      let winKey = "";
      const closedTab = this.tabs.splice(inx, 1)[0];
      if (inx === this.tabs.length) {
        this.tabIndex = inx - 1;
      } else {
        this.tabIndex = inx;
      }
      this.urlInput = this.tabs[this.tabIndex].m_url;
      this.urlInputs = this.tabs[this.tabIndex].m_url;
      if (this.tabs.length >= 1) {
        winKey = `${closedTab.winKey},${this.tabs[this.tabIndex].winKey}`;
      } else {
        winKey = closedTab.winKey;
      }
      const params = { winKey };
      if (window.webkit) {
        window.webkit.messageHandlers.macCloseTab.postMessage(
          JSON.stringify(params)
        );
      }
      ajax({
        url:
          (process.env.NODE_ENV == "production"
            ? `${process.env.VUE_APP_BASE_API}`
            : "http://creword.com") + "/notifiedClient/closeTab",
        params,
      });
    },
    //选择标签页
    selectTabPage(inx) {
      if (this.tabIndex !== inx) {
        this.tabIndex = inx;
        this.urlInput = this.tabs[this.tabIndex].m_url;
        this.urlInputs = this.tabs[this.tabIndex].m_url;
        const params = {
          winKey: this.tabs[this.tabIndex].winKey,
        };
        if (window.webkit) {
          window.webkit.messageHandlers.macSelectTab.postMessage(
            JSON.stringify(params)
          );
        }
        ajax({
          url:
            (process.env.NODE_ENV == "production"
              ? `${process.env.VUE_APP_BASE_API}`
              : "http://creword.com") + "/notifiedClient/selectTab",
          params,
        });
      }
    },
    //加载首页
    homePage() {
      window.ShowDownLoadTips();
      // this.urlInput = "";
      // this.urlInputs = "";
      // this.showDataCollectionMain = true;
      // this.$store.commit("changeQueryLink", "");
      // const winKey = this.tabs[this.tabIndex].winKey
      // Object.assign(this.tabs[this.tabIndex], {
      //   url: this.defaultUrl,
      // })
      // const params = {
      //   url: this.defaultUrl,
      //   winKey,
      // }
      // if (window.webkit) {
      //   window.webkit.messageHandlers.macHomePage.postMessage(
      //     JSON.stringify(params)
      //   )
      // }
      // ajax({
      //   url:
      //     (process.env.NODE_ENV == 'production'
      //       ? `${process.env.VUE_APP_BASE_API}`
      //       : 'http://creword.com') + '/notifiedClient/homepage',
      //   params,
      // })
    },
    //刷新当前标签页
    refresh() {
      if (this.urlInput) {
        this.showIfare = false;
        this.$nextTick(() => {
          this.showIfare = true;
          this.locationSend();
        });
      } else {
        this.urlInput = "";
        this.urlInputs = "";
        this.showDataCollectionMain = true;
      }
      // this.locationSend()
      // const params = {
      //   winKey: this.tabs[this.tabIndex].winKey,
      // }
      // if (window.webkit) {
      //   window.webkit.messageHandlers.macRefresh.postMessage(
      //     JSON.stringify(params)
      //   )
      // }
      // try {
      //   this.animated = true
      //   ajax({
      //     url:
      //       (process.env.NODE_ENV == 'production'
      //         ? `${process.env.VUE_APP_BASE_API}`
      //         : 'http://creword.com') + '/notifiedClient/refreshWin',
      //     params,
      //   })
      // } catch (e) {
      //   console.log(e.name + e.message)
      // }
      // setTimeout(() => {
      //   this.animated = false
      // }, 500)
    },
    //后退 -- 左
    backLink() {
      console.log(this.tabs);
      if (this.listIndex == -1 || this.listIndex == 0) {
        return;
      }
      const url = this.histroyList[--this.listIndex];
      if (url != "index") {
        this.urlInput = url;
        this.contentSrc = this.urlInput;
        this.showDataCollectionMain = false;
      } else {
        this.homePage();
      }

      // console.log(this.listIndex);
      // console.log(this.tabs);
      // this.locationSend();

      // const url = this.tabs[this.tabIndex].m_url;

      // try {
      //   // eslint-disable-next-line
      //   PageBack();
      // } catch (e) {
      //   console.log(e);
      // }
      // if (this.back) {
      //   const params = {
      //     winKey: this.tabs[this.tabIndex].winKey,
      //   }
      //   if (window.webkit) {
      //     window.webkit.messageHandlers.macBackLink.postMessage(
      //       JSON.stringify(params)
      //     )
      //   }
      //   ajax({
      //     url:
      //       (process.env.NODE_ENV == 'production'
      //         ? `${process.env.VUE_APP_BASE_API}`
      //         : 'http://creword.com') + '/notifiedClient/backLink',
      //     params,
      //   })
      // }
    },
    //前进 -- 右
    forwardLink() {
      if (this.listIndex == this.histroyList.length - 1) {
        return;
      }

      this.urlInput = this.histroyList[++this.listIndex];

      // this.locationSend();
      this.showDataCollectionMain = false;
      // const url = this.tabs[this.tabIndex].m_url;
      this.contentSrc = this.urlInput;
      // try {
      //   // eslint-disable-next-line
      //   PageForward();
      // } catch (e) {
      //   console.log(e);
      // }
      // if (this.forward) {
      //   const params = {
      //     winKey: this.tabs[this.tabIndex].winKey,
      //   }
      //   if (window.webkit) {
      //     window.webkit.messageHandlers.macForwardLink.postMessage(
      //       JSON.stringify(params)
      //     )
      //   }
      //   ajax({
      //     url:
      //       (process.env.NODE_ENV == 'production'
      //         ? `${process.env.VUE_APP_BASE_API}`
      //         : 'http://creword.com') + '/notifiedClient/forwardLink',
      //     params,
      //   })
      // }
    },
    //地址栏发送
    locationSend() {
      window.ShowDownLoadTips();
      // if (!this.urlInput) return;
      // let reg = /(([\w-]+\.)+[a-z0-9]+)/;
      // if (!reg.test(this.urlInput)) {
      //   this.urlInput = "";
      //   return this.$message({
      //     type: "error",
      //     message: `请输入正确的网址`,
      //     duration: 2000,
      //   });
      // }

      // if (this.urlInput.indexOf("http") == -1) {
      //   this.urlInput = "http://" + this.urlInput;
      // }

      // this.histroyList.push(this.urlInput);
      // this.listIndex++;
      // // console.log(this.tabs[this.tabIndex].m_url);
      // this.showDataCollectionMain = false;
      // // const url = this.tabs[this.tabIndex].m_url;
      // this.contentSrc = this.urlInput;

      this.$nextTick(() => {
        // let data = this.$refs.refed.contentWindow.document.body
        // console.log(data)
        // let iframe = document.getElementById('iframe1')
        // console.log(iframe)
        // let iframeWin = iframe.contentWindow
        // let iframeDoc = iframeWin.document
        // iframeWin.onLoad = function (e) {
        //   console.log(iframeDoc.body)
        // }
        // console.log(1111)
        // console.log(iframeDoc.getElementById('suggest-align'))
        // iframeDoc.addEventListener('mousedown', function (e) {
        //   const target = e.target || e.srcElement
        //   console.log(e.target)
        //   if (target.tagName == 'A') {
        //     target.setAttribute('target', '_self')
        //     iframeWin.postMessage(
        //       JSON.stringify({
        //         action: 'location',
        //         data: {
        //           url: target.href,
        //         },
        //       })
        //     )
        //   }
        // })
      });

      // const winKey = this.tabs[this.tabIndex].winKey;
      // Object.assign(this.tabs[this.tabIndex], { url });
      // window.location.href = url;
      // ajax({
      //   url: "http://creword.com/notifiedClient/openLink",
      //   params: {
      //     url,
      //     winKey,
      //   },
      // });
    },
    // //地址栏发送
    // locationSend() {
    //   if (!this.urlInput) return
    //   const url = this.tabs[this.tabIndex].m_url
    //   const winKey = this.tabs[this.tabIndex].winKey
    //   Object.assign(this.tabs[this.tabIndex], { url })
    //   const params = {
    //     url,
    //     winKey,
    //   }
    //   if (window.webkit) {
    //     window.webkit.messageHandlers.macOpenLink.postMessage(
    //       JSON.stringify(params)
    //     )
    //   }
    //   ajax({
    //     url:
    //       (process.env.NODE_ENV == 'production'
    //         ? `${process.env.VUE_APP_BASE_API}`
    //         : 'http://creword.com') + '/notifiedClient/openLink',
    //     params,
    //   })
    // },
    //地址栏回车
    handleKeydownUrl(e) {
      window.ShowDownLoadTips();
      // const key = e.keyCode || e.which;
      // if (key == 13) {
      //   this.locationSend();
      // }
    },
    //收藏网址
    collectUrl() {
      if (!this.urlInput) return;
      const url = this.tabs[this.tabIndex].m_url;
      const params = { url };
      if (window.webkit) {
        window.webkit.messageHandlers.macCollectionLink.postMessage(
          JSON.stringify(params)
        );
      }
      ajax({
        url:
          (process.env.NODE_ENV == "production"
            ? `${process.env.VUE_APP_BASE_API}`
            : "http://creword.com") + "/notifiedClient/collectionLink",
        params,
      });
    },
    //网页采集
    async dataAcquisition() {
      window.ShowDownLoadTips();
      // const result = await isHasFreeCount({ menuName: "网页采集" });
      // if (result.code == 415) {
      //   this.$store.commit("setBuyTipsInfo", result.data);
      //   this.$bus.$emit("buyTips");
      //   return;
      // }

      // if (result.data.title.indexOf("校验") == -1) {
      //   this.$message({
      //     type: "success",
      //     message: result.message,
      //     customClass: "short-message",
      //     duration: 2000,
      //   });
      // }
      // const form = new FormData();
      // const url = this.urlInput;
      // form.append("url", url);
      // this.showCollectProgress = true;
      // try {
      //   const res = await gather(form);
      //   if (res.code == 200) {
      //     this.collectPercent = 100;
      //     setTimeout(() => {
      //       this.collectPercent = 0;
      //       this.showCollectProgress = false;
      //     }, 800);
      //     this.$message({
      //       type: "success",
      //       message: `采集成功`,
      //       customClass: "short-message",
      //       duration: 3000,
      //     });
      //     this.twinkling = !this.twinkling;

      //     setTimeout(() => {
      //       this.twinkling = !this.twinkling;
      //     }, 5000);
      //   }
      // } catch {
      //   this.collectPercent = 100;
      //   setTimeout(() => {
      //     this.collectPercent = 0;
      //     this.showCollectProgress = false;
      //   }, 800);
      //   this.$message({
      //     type: "error",
      //     message: `采集失败！`,
      //     customClass: "short-message",
      //     duration: 800,
      //   });
      // }
    },
    //打开网盘网页采集文件夹
    locationDiskCollectFolder() {
      window.ShowDownLoadTips();
      // this.$store.commit("ChangeSideBarId", 66);
      // window.location.href = "http://office.creword.com/disk";
      // if (window.webkit) {
      //   window.webkit.messageHandlers.macLocationDiskCollectFolder.postMessage(
      //     null
      //   )
      // }
      // ajax({
      //   url:
      //     (process.env.NODE_ENV == 'production'
      //       ? `${process.env.VUE_APP_BASE_API}`
      //       : 'http://creword.com') +
      //     '/notifiedClient/locationDiskCollectFolder',
      // })
    },
    handleCommand(val) {
      this.httpTop = val;
    },
    scrollTo() {
      let maxScrollDis =
        this.$refs["tabs-scroll"].clientWidth -
        this.$refs["tabs"].clientWidth +
        24;
      this.$refs["tabs"].scrollLeft = maxScrollDis;
    },
  },
  watch: {
    urlInput(url) {
      // const reg = /^(http|https)\:\/\//
      // if (reg.test(url)) {
      //   this.httpTop = /^https\:\/\//.test(url) ? 'https://' : 'http://'
      // }
      // this.urlInput = reg.test(url) ? url.replace(reg, '') : url
      Object.assign(this.tabs[this.tabIndex], {
        m_url: url,
      });
    },
    httpTop(http) {
      const target = this.tabs[this.tabIndex];
      const reg = /^(http|https)\:\/\//;
      Object.assign(this.tabs[this.tabIndex], {
        m_url: target.m_url.replace(reg, http),
      });
    },
  },

  created() {
    /**
     * 搜索新增标签页
     * @param { String } url 搜索url
     * @param { String } title 页面标题
     * @param { String } icon 页面图标
     */
    window.openNewWindow = (url, title, icon) => {
      this.addTabPage(url, title, icon);
    };

    /**
     * winf更新当前tab页前进后退状态
     * @param { Boolean } forward 是否可以向右
     * @param { Boolean } back 是否可以向左
     * @param { String } key 当前窗口的key
     */
    window.checkForwardAndBackoff = (forward, back, key) => {
      this.tabs.forEach((i) => {
        if (i.winKey == key) {
          this.forward = forward;
          this.back = back;
        }
      });
    };
    /**
     * winf更新前进后退之后，更新当前历史页面的url,title,ico图标
     * @param { String } url 地址
     * @param { string } title 地址标题
     * @param { String } icon 地址ico图标路径
     * @param { String } key 当前窗口的winKey
     */
    window.updateTabHistory = (url, title, icon, key) => {
      this.tabs.forEach((i) => {
        if (i.winKey == key) {
          if (url != this.defaultUrl) {
            this.urlInput = url;
            this.urlInputs = url;
          } else {
            this.urlInput = "";
            this.urlInputs = "";
          }
          Object.assign(this.tabs[this.tabIndex], {
            url,
            title,
            icon,
          });
        }
      });
    };
    /**
     * 刷新tab页的图标和title文本，以及url
     * @param { Boolean } forward 是否可以向右
     * @param { Boolean } back 是否可以向左
     * @param { String } key 当前窗口的key
     */
    window.updateWindow = (winKey, url, title, icon) => {
      
      this.tabs.forEach((i) => {
        if (i.winKey == winKey) {
          i.icon = icon;
          i.url = url;
          i.title = title;
        }
      });
    };

    //设置网盘sidebar id
    window.setDiskSideId = () => {
      this.$store.commit("ChangeSideBarId", 66);
    };
    /**
     * 提示消息
     * @param { String } type success-成功 error-失败 warning-警告 info-消息
     * @param { String } mes 消息内容
     */
    window.collectOverMessage = (type, mes) => {
      this.$message({
        type,
        message: mes,
        customClass: "short-message",
        duration: 1500,
      });
    };
    // alert(this.$route.query.url);
    // alert(location.search);
    // let url = "";
    // location.search
    //   .substring(1)
    //   .split("&")
    //   .forEach((item) => {
    //     const paramsList = item.split("=");
    //     if (paramsList[0] == "url") {
    //       url = decodeURIComponent(paramsList[1]);
    //     }
    //   });
    // alert(url);

    if (this.$store.state.queryItemLink) {
      // alert(this.$route.query.url);
      this.urlInput = this.$store.state.queryItemLink;
      this.$nextTick(() => {
        this.locationSend();
      });
      // setTimeout(() => {
      //   this.$store.commit("changeQueryLink", "");
      // }, 1000);
    } else {
      if (this.$store.state.urlInput) {
        this.urlInput = this.$store.state.urlInput;
        this.$nextTick(() => {
          this.locationSend();
        });
        setTimeout(() => {
          this.$store.commit("GeturlInput", "");
        }, 1000);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  min-height: 94px !important;
}
.el-tooltip__popper.is-light {
  top: 20px !important;
  border: none;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
}
</style>
<style lang="scss" scoped>
.data-collection-toolbar {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;

  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      position: relative;
      width: 936px;
      margin-right: 20px;
      background-color: #fff;
      height: 70vh;
    }
    .right {
      background-color: #fff;
      flex: 1;
    }
  }
  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }

  //标签栏
  &-tabbar {
    @include flex-start;
    height: 33px;
    background-color: #f7f7f7;
    .tabs {
      @include flex-start;
      flex: 1;
      overflow-x: scroll;
      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 10px;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ff6900;
      }
      &-scroll {
        display: flex;
        padding-left: 5px;
        .tab-item {
          @include noselect;
          position: relative;
          display: flex;
          flex-shrink: 0;
          width: 128px;
          height: 30px;
          padding: 0 10px;
          background: #f7f7f7;
          border-radius: 8px 8px 0px 0px;
          box-sizing: border-box;
          cursor: default;
          &-inner {
            @include flex-start;
            flex: 1;
            .icon {
              img {
                width: 14px;
                height: 14px;
              }
            }
            .title {
              max-width: 70px;
              white-space: nowrap;
              overflow: hidden;
              margin-left: 8px;
              font-size: 12px;
              font-weight: 500;
              color: #333333;
            }
          }
          .onekey-collect {
            @include flex-start;
            img {
              width: 16px;
              height: 16px;

              cursor: pointer;
            }
            .title {
              margin: 0 5px;
              font-size: 12px;
              font-weight: 500;
              color: #333333;
            }
          }
          .close {
            @include noselect;
            position: absolute;
            right: 10px;
            width: 8px;
            height: 8px;
            top: calc(50% - 4px);
            cursor: pointer;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: url("~assets/images/dataCollection/close.png")
                no-repeat;
              background-size: 100%;
            }
          }
          &.tab-item-active {
            background-color: #fff;
          }
        }
      }
      .add {
        @include noselect;
        margin-left: 10px;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .history-drop {
      @include flex-start;
      flex-shrink: 0;
      position: relative;
      width: 12px;
      height: 30px;
      margin: 0 18px 0 100px;
      .drop-icon {
        position: absolute;
        width: 12px;
        height: 8px;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: url("~assets/images/dataCollection/drop-icon.png")
            no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  //地址栏
  &-addressbar {
    .url_box {
      display: flex;
      align-items: center;
      padding: 25px 30px;
      .menu {
        display: flex;

        .docs,
        .home {
          margin: 0 10px;
        }

        .option {
          margin: 0 5px;
        }
        .refresh {
          margin: 0 10px;
        }

        &-item {
          // @include flex-center(column);
          display: flex;
          margin-right: 5px;
          // justify-content: stretch;
          // align-items: center;
          position: relative;
          cursor: pointer;
          &:first-child {
            padding: 0 5px;
          }
          > img {
            width: 20px;
            height: 20px;
            // margin: 0 10px;
          }
          > span {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
          }
        }
        .option:hover {
          background-color: #ebe9e9;
          border-radius: 50%;
        }
        .collect {
          position: relative;
        }
        .refresh {
          @keyframes refreshrotate {
            25% {
              transform: rotate(45deg);
            }
            50% {
              transform: rotate(90deg);
            }
            75% {
              transform: rotate(180deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          animation: refreshrorate 2s linear infinite;
          &.is-animate {
            img {
              animation: refreshrorate 2s linear infinite;
            }
          }
        }
      }
      .input_url_box {
        flex: 1;
        display: flex;
        height: 30px;
        align-items: center;
        border: 1px solid #e8e9f0;
        position: relative;
        // .el-dropdown {
        //   @include flex-start;
        //   cursor: pointer;
        //   line-height: 30px;
        //   &:hover .el-dropdown-link {
        //     border-color: #26b248;
        //   }
        //   .el-dropdown-link {
        //     width: 95px;
        //     height: 30px;
        //     padding-left: 20px;
        //     color: #26b248;
        //     border: 1px solid transparent;
        //     transition-duration: 0.3s;
        //     transition-property: border;
        //     box-sizing: border-box;
        //   }
        // }
        .input_box {
          flex: 1;
          display: flex;
          align-items: center;
          ::v-deep .el-input {
            flex: 1;
            .el-input__inner {
              border: 0px;
              height: 100%;
              // line-height: 30px;
            }
            // .el-input__inner::-webkit-input-placeholder {
            //   /* 使用webkit内核的浏览器 */
            //   // color: #e0484b;
            //   // line-height: 30px;
            // }
            .el-input__suffix {
              position: absolute;
              right: 0%;
              top: -30%;
              // top: 50%;
              // transform: translateY(-50%);
              i.el-input__icon {
                line-height: 30px;
              }
            }
          }
          img {
            width: 14px;
            height: 15px;
            margin: 0;
            margin-right: 5px;
          }
        }
      }
    }
  }
  //书签栏
  &-bookmarkbar {
    .collect {
      font-size: 12px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e8e9f0;
      .img_collect {
        width: 30px;
        cursor: pointer;
        img {
          @include noselect;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        border-right: 1px #e8e9f0 solid;
      }
      .collect_item_box {
        display: flex;
        flex: 1;
        padding: 0 5px;
        white-space: nowrap;
        overflow: hidden;
        .collect_item {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0 5px;
          img {
            margin-right: 5px;
            width: 14px;
            height: 14px;
          }
          .keyword {
            max-width: 60px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            font-weight: 500;
            color: #333;
          }
        }
      }
      .collect-drop {
        @include flex-start;
        position: relative;
        width: 10px;
        height: 10px;
        .drop-icon {
          position: relative;
          width: 10px;
          height: 10px;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("~assets/images/dataCollection/excursion.png")
              no-repeat;
            background-size: 100%;
          }
        }
        .drop-menu {
          @include flex-center(column);
          @include noselect;
          justify-content: normal;
          align-items: normal;
          position: absolute;
          display: none;
          top: -72px;
          right: 18px;
          width: 200px;
          max-height: 92px;
          padding: 5px 0;
          background: #ffffff;
          border: 2px solid #e3e4e7;
          box-sizing: border-box;
          overflow: auto;
          cursor: default;
          &.drop-menu-active {
            display: flex;
          }
          &-item {
            @include flex-start;
            padding: 6px;
            img {
              width: 14px;
              height: 14px;
            }
            .keyword {
              max-width: 169px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-left: 5px;
              font-size: 13px;
              font-weight: 400;
              color: #333333;
            }
            &:hover {
              background-color: rgba(32, 33, 36, 0.2);
            }
          }
          &::-webkit-scrollbar-track {
            display: none;
          }

          &::-webkit-scrollbar {
            width: 2px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(69, 136, 255, 0.6);
          }
        }
        &:hover .drop-icon {
          &::before {
            background: url("~assets/images/dataCollection/excursion-blue.png")
              no-repeat;
            background-size: 100%;
          }
        }
      }
    }
  }
  .books-contextmenu {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    position: fixed;
    z-index: 1000;
    visibility: none;
    opacity: 0;
    top: 0;
    left: -1000px;
    min-height: 30px;
    min-width: 60px;
    padding: 5px;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.3);
    transition: opacity 0.3;
    .item {
      @include noselect;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      cursor: pointer;
      &:hover {
        color: #ff6900;
      }
    }
    &.active {
      visibility: visible;
      opacity: 1;
    }
    &.inactive {
      z-index: -1;
    }
  }
  .waver-network {
    display: none;
  }
  .className {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -10px !important;
    margin-bottom: 20px;
    animation: twinkling 0.8s 2 ease-in-out;
  }

  @keyframes twinkling {
    0% {
      opacity: 0.5;
      width: 22px;
      height: 22px;
    }
    100% {
      opacity: 1;
      width: 18px;
      height: 18px;
    }
  }
}
.DataCollectionMain_content {
  margin: 0 auto;
}
</style>
