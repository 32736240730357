<template>
  <div class="data-collection-progress" ref="outer">
    <div class="data-collection-progress-inner" ref="inner"></div>
  </div>
</template>

<script>
export default {
  name: 'DataCollectionProgress',
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      percent: this.percentage,
    }
  },
  methods: {
    load() {
      const outer = this.$refs['outer']
      const inner = this.$refs['inner']
      const outerWidth = outer.clientWidth
      let dis = 0
      const b = outerWidth / 100
      let timer1, timer2, timer3
      timer1 = setInterval(() => {
        if (dis >= 50) {
          clearInterval(timer1)
          timer2 = setInterval(() => {
            dis += Math.floor(Math.random() * 3 + 1)
            inner.style.width = dis * b + 'px'
            if (dis >= 80) {
              clearInterval(timer2)
            }
          }, 200)
        }
        dis += 3
        inner.style.width = dis * b + 'px'
      }, 100)
      timer3 = setInterval(() => {
        if (this.percentage == 100) {
          clearInterval(timer1)
          clearInterval(timer2)
          clearInterval(timer3)
          inner.style.width = 100 * b + 'px'
        }
      }, 50)
    },
  },
  mounted() {
    this.$refs['inner'].style.width = 0
    this.load()
  },
}
</script>

<style lang="scss" scoped>
.data-collection-progress {
  position: fixed;
  top: 50px;
  left: 0;
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  border: 1px solid #eee;
  box-sizing: border-box;
  &-inner {
    width: 0;
    height: 2px;
    background-color: rgba(76, 169, 231, 0.6);
  }
}
</style>
